import React from "react";

const notFoundStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
};

const contentStyles = {
    textAlign: 'center',
};

const titleStyles = {
    fontSize: '100px',
    margin: '0',
    color: '#333',
};

const descriptionStyles = {
    fontSize: '24px',
    margin: '20px',
};

const linkStyles = {
    textDecoration: 'none',
    fontSize: '18px',
    color: '#0074d9',
};

export const PageNotFound = () => {
    return (
        <div style={notFoundStyles}>
            <div style={contentStyles}>
                <h1 style={titleStyles}>404</h1>
                <p style={descriptionStyles}>Oops! The page you are looking for does not exist.</p>
                <a style={linkStyles} href="/">
                    Go to homepage
                </a>
            </div>
        </div>
    );
};
