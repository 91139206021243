import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import { TextDecrypt } from "../content/TextDecrypt";
import Resume from "../../settings/resume.json";

const useStyles = makeStyles((theme) => ({
  footerText: {
    position: "absolute",
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    "&:hover": {
      color: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

export const FooterText = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const crText = "Ⓒ {year} sabuj.in";
  const finalCrText = crText.replace("{year}", currentYear);

  return (
    <Link
      color="inherit"
      underline="none"
      href="/"
      target="_blank"
      rel="noopener noreferrer"
      className={classes.footerText}
    >
      <Typography variant="body1">
        <TextDecrypt text={finalCrText} />
      </Typography>
    </Link>
  );
};
