import React from "react";

export const Resume = () => {
    const pdfUrl = 'https://drive.google.com/uc?export=download&id=1PtCRfggiav31gy62kgukXiDH6TbXaFat';

    return (
        <div style={{ height: '100vh' }}>
            <iframe
                title="PDF Viewer"
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
                    pdfUrl
                )}`}
                width="100%"
                height="100%" // Set the iframe height to 100%
                frameBorder="0"
            ></iframe>
        </div>
    );
};
